<template>
  <div id="messaging-container" class="card">
    <div
      id="conversations-list"
      v-bind:class="{ 'conversation-selected': params.conversation }"
    >
      <div id="conversations-search">
        <div id="search-container">
          <input
            type="text"
            id="search"
            v-model="searchQuery"
            placeholder="Search conversations"
          />
          <i class="fa fa-search search-icon"></i>
        </div>
        <div class="conversations-filters">
          <button
            v-for="filter in filters"
            :key="filter"
            class="small orange conversations-filter"
            :class="{ active: selectedFilter === filter }"
            @click="selectedFilter = filter"
          >
            {{ filter }}
          </button>
        </div>
      </div>
      <div id="conversations">
        <div
          v-for="conversation in filteredConversations"
          v-if="filteredConversations.length > 0"
          :key="conversation.id"
          class="conversations-list-item"
          :class="{ active: selectedConversation.id === conversation.id }"
          @click="setConversationId(conversation.id)"
        >
          <div class="avatar">
            <!-- Use a placeholder image for the avatar -->
            <div class="profile-initials">
              {{ nameToInitials(conversation.other_member.full_name) }}
            </div>
          </div>
          <div class="conversation-item-content">
            <div class="conversation-item-header">
              <span class="conversation-item-title">{{
                conversation.other_member.full_name
              }}</span>
              <span class="conversation-item-time">{{
                timeAgo(conversation.updated_at)
              }}</span>
            </div>
            <div class="conversation-item-message">
              {{ conversation.latest_message || '-' }}
            </div>
            <div class="conversation-item-icons">
              <span
                v-if="
                  conversation.pending &&
                  !conversation.declined &&
                  !conversation.is_recipient
                "
                class="pending"
                >pending</span
              >
              <span
                v-if="
                  conversation.pending &&
                  !conversation.declined &&
                  conversation.is_recipient
                "
                class="new"
                >new</span
              >
              <span v-if="conversation.declined" class="declined"
                >declined</span
              >
              <i
                v-if="!conversation.read"
                class="fa-solid fa-circle unread"
              ></i>
              <i
                v-if="conversation.starred"
                class="fa-solid fa-star starred"
              ></i>
            </div>
          </div>
        </div>
        <div v-else class="text-muted mt-3 text-center w-100">
          No conversations found
        </div>
      </div>
    </div>
    <div
      id="conversation-container"
      v-bind:class="{ 'conversation-selected': params.conversation }"
      v-if="selectedConversation"
    >
      <div id="conversation-header">
        <div class="back-button">
          <button class="btn btn-link" @click="setConversationId(null)">
            <i class="fa fa-arrow-left fa-2x"></i>
          </button>
        </div>
        <div class="avatar">
          <div class="profile-initials">
            {{ nameToInitials(selectedConversation.other_member.full_name) }}
          </div>
        </div>
        <div class="conversation-header-info">
          <div class="conversation-header-title">
            {{ selectedConversation?.other_member.full_name || '-' }}
          </div>
          <div>
            {{ selectedConversation?.other_member.job_title || '' }} at
            <a
              target="_blank"
              :href="selectedConversation.other_member.organization_url"
              >{{ selectedConversation.other_member.organization }}</a
            >
          </div>
        </div>
        <div class="conversation-header-actions">
          <button
            class="btn-link sun-light"
            v-if="selectedConversation.starred"
            @click="unstarConversation(selectedConversation)"
          >
            <i class="fa-solid fa-star fa-2x"></i>
          </button>
          <button
            class="btn-link gray-light"
            v-else
            @click="starConversation(selectedConversation)"
          >
            <i class="fa-solid fa-star fa-2x"></i>
          </button>
        </div>
      </div>
      <div id="messages-list">
        <div
          v-for="(message, index) in messages"
          :key="message.id"
          :class="{
            'message left': !message.is_sender,
            'message right': message.is_sender,
            'display-time': shouldDisplayTime(index),
          }"
        >
          {{ message.text }}
          <div class="message-time" v-if="shouldDisplayTime(index)">
            {{ timeAgo(message.created_at) }}
          </div>
        </div>
        <div
          v-if="
            selectedConversation.is_recipient &&
            (selectedConversation.pending || selectedConversation.declined)
          "
          class="text-center text-muted mt-3 p-5"
        >
          <h3>
            This is a new conversation request from
            {{ selectedConversation.other_member.full_name }}
          </h3>
          <p>
            You can continue this conversation after you've accepted this
            request, or you can decline this request to let the other person
            know you aren't interested.
          </p>

          <button class="btn orange mx-3" @click="acceptConversation">
            Accept
          </button>
          <button class="btn orange outline mx-3" @click="declineConversation">
            Decline
          </button>
        </div>
        <div
          v-if="
            !selectedConversation.is_recipient && selectedConversation.pending
          "
          class="text-center text-muted mt-3 p-5"
        >
          <h3>
            The other user has not accepted your conversation request yet. You
            can continue your conversation after your request has been accepted.
          </h3>
        </div>
      </div>
      <div
        id="message-input"
        v-if="!selectedConversation.pending && !selectedConversation.declined"
      >
        <div class="scroll-button-container" v-if="shouldScrollDown">
          <button
            class="btn orange small"
            id="scroll-down-button"
            @click="scrollToBottom"
          >
            New message<i class="fa fa-arrow-down fa-bounce"></i>
          </button>
        </div>
        <div class="textarea-container">
          <contenteditable
            :no-nl="false"
            :contenteditable="true"
            tag="div"
            id="message"
            v-model="message"
            @keydown.enter="handleKeyDown"
            :placeholder="`Type a message.\nPress SHIFT + Enter for a new line.`"
          ></contenteditable>
          <button
            class="green medium"
            id="send-message"
            :disabled="!message.trim()"
            @click="sendMessage"
          >
            Send <i class="fa fa-paper-plane"></i>
          </button>
        </div>
      </div>
    </div>
    <div id="conversation-container" class="p-5 text-center text-muted" v-else>
      No conversation selected
    </div>
  </div>
</template>
<script setup>
import contenteditable from 'vue-contenteditable'
</script>
<script>
import { timeAgo } from '../common/DateHelpers'
import { get, post } from '../api'

import { useUrlSearchParams } from '@vueuse/core'

const params = useUrlSearchParams('history')

export default {
  data() {
    return {
      params,
      searchQuery: '',
      filters: ['Inbox', 'Unread', 'Pending', 'Starred', 'Archive'],
      selectedFilter: 'Inbox',
      conversations: [],
      selectedConversation: null,
      messages: [],
      message: '',
      shouldScrollDown: false,
    }
  },
  methods: {
    timeAgo,
    nameToInitials(name) {
      return name
        .split(' ')
        .slice(0, 3)
        .map((word) => word[0])
        .join('')
    },
    scrollToBottom() {
      const container = document.getElementById('messages-list')
      const duration = 200 // Duration of the animation in milliseconds
      const start = window.performance.now() // Start time
      const from = container.scrollTop // Initial scroll position
      const to = container.scrollHeight - container.clientHeight // Target scroll position

      if (from < to) {
        const step = (now) => {
          const elapsed = now - start // Elapsed time
          const progress = Math.min(elapsed / duration, 1) // Progress of the animation
          container.scrollTop = from + (to - from) * progress // Update scroll position based on progress

          if (progress < 1) {
            window.requestAnimationFrame(step) // Continue the animation
          }
        }
        window.requestAnimationFrame(step) // Start the animation
      }
    },
    shouldDisplayTime(index) {
      if (index < this.messages.length - 1) {
        if (
          this.messages[index].is_sender !== this.messages[index + 1].is_sender
        ) {
          return true
        }
        const currentMessageTime = new Date(this.messages[index].created_at)
        const nextMessageTime = new Date(this.messages[index + 1].created_at)
        const differenceInMinutes =
          (nextMessageTime - currentMessageTime) / 1000 / 60
        return differenceInMinutes > 10
      }
      return true // Always display time for the last message
    },
    handleKeyDown(e) {
      if (!e.shiftKey && e.key === 'Enter') {
        e.preventDefault()
        this.sendMessage()
      }
    },
    async fetchConversations() {
      try {
        const body = await get('/messaging/api/conversations/')
        this.conversations = body.conversations
        this.updateSelectedConversation()
      } catch (error) {
        console.error(error)
      }
    },
    setConversationId(conversationId) {
      this.params.conversation = conversationId
      this.updateSelectedConversation()
    },
    async sendMessage() {
      if (this.message.trim() !== '') {
        try {
          const response = await post(
            `/messaging/api/conversation/${this.selectedConversation.id}/`,
            { message: this.message }
          )
          if (response.status == 429) {
            alert(
              'You are sending messages too quickly. Please wait a few seconds before sending another message.'
            )
            return
          }

          this.message = '' // Clear input after sending
          await this.fetchMessages(true) // Fetch messages again to update the list
          await this.fetchConversations() // Fetch conversations again to update the list
        } catch (error) {
          console.error(error)
        }
      }
    },
    async fetchMessages(showUserNudge = false) {
      if (this.selectedConversation !== null) {
        const body = await get(
          `/messaging/api/conversation/${this.selectedConversation.id}/`
        )
        const container = document.getElementById('messages-list')

        //TODO check if this does not create an enormous amount of event listeners on the container
        container.onscroll = () => {
          if (
            container.scrollTop >=
            container.scrollHeight - container.clientHeight - 30
          ) {
            this.shouldScrollDown = false
            this.markConversationAsRead(this.selectedConversation)
          }
        }

        const newMessages = this.messages.length < body.messages.length
        //Check if user is at bottom of container Add a coulance of 30px to the scroll position to account for rounding errors
        const userIsAtBottom =
          container.scrollTop >=
          container.scrollHeight - container.clientHeight - 30

        this.messages = body.messages

        if (!newMessages) return

        await this.$nextTick(() => {
          if (showUserNudge && !userIsAtBottom) {
            this.shouldScrollDown = true
          } else {
            this.markConversationAsRead(this.selectedConversation)
            this.shouldScrollDown = false
            container.scrollTop = container.scrollHeight
          }
        })
      }
    },
    updateSelectedConversation() {
      const conversationId = params.conversation || null
      if (conversationId !== null) {
        const selectedConversations = this.conversations.filter(
          (conversation) => conversation.id === conversationId
        )

        if (selectedConversations.length > 0) {
          this.selectedConversation = selectedConversations[0]
          return
        }
      }
      if (this.conversations.length > 0) {
        this.selectedConversation = this.conversations[0]
        return
      }

      this.selectedConversation = null
    },
    starConversation(conversation) {
      conversation.starred = true
      post(`/messaging/api/conversation/${conversation.id}/star/`)
    },
    unstarConversation(conversation) {
      conversation.starred = false
      post(`/messaging/api/conversation/${conversation.id}/unstar/`)
    },
    markConversationAsRead(conversation) {
      if (conversation.read) return
      conversation.read = true
      post(`/messaging/api/conversation/${conversation.id}/read/`)
    },
    async acceptConversation() {
      await post(
        `/messaging/api/conversation/${this.selectedConversation.id}/accept/`
      )
      this.selectedConversation.pending = false
    },
    async declineConversation() {
      await post(
        `/messaging/api/conversation/${this.selectedConversation.id}/decline/`
      )
      this.selectedConversation.pending = false
      this.selectedConversation.declined = true
    },
  },
  watch: {
    async selectedConversation(oldValue, newValue) {
      if (oldValue == newValue || oldValue?.id == newValue?.id) {
        return
      }
      this.messages = []
      await this.fetchMessages(false)
      this.shouldScrollDown = false
      if (this.selectedConversation.read === false) {
        await this.markConversationAsRead(this.selectedConversation)
      }
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.fetchConversations()
      if (this.selectedConversation !== null) {
        this.fetchMessages(true)
      }
    }, 5000)
    this.fetchConversations()
  },
  computed: {
    filteredConversations() {
      let conversations = this.conversations
      if (this.searchQuery && this.searchQuery !== '') {
        conversations = conversations.filter((conversation) =>
          conversation.other_member.full_name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        )
      }
      switch (this.selectedFilter) {
        case 'Inbox':
          return conversations.filter((conversation) => !conversation.declined)
        case 'Unread':
          return conversations.filter(
            (conversation) => !conversation.read && !conversation.declined
          )
        case 'Pending':
          return conversations.filter(
            (conversation) => conversation.pending && !conversation.declined
          )
        case 'Starred':
          return conversations.filter((conversation) => conversation.starred)
        case 'Archive':
          return conversations.filter((conversation) => conversation.declined)
        default:
          return conversations
      }
    },
  },
}
</script>

<style scoped></style>
